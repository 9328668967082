export default class Character {
    static list = [
        { number: 101, name: "ジン" },
        { number: 102, name: "アンバー" },
        { number: 103, name: "リサ" },
        { number: 104, name: "ガイア" },
        { number: 105, name: "バーバラ" },
        { number: 106, name: "ディルック" },
        { number: 107, name: "レザー" },
        { number: 108, name: "ウェンティ" },
        { number: 109, name: "クレー" },
        { number: 110, name: "ベネット" },
        { number: 111, name: "ノエル" },
        { number: 112, name: "フィッシュル" },
        { number: 113, name: "スクロース" },
        { number: 114, name: "モナ" },
        { number: 115, name: "ディオナ" },
        { number: 116, name: "アルベド" },
        { number: 117, name: "ロサリア" },
        { number: 201, name: "魈" },
        { number: 202, name: "北斗" },
        { number: 203, name: "凝光" },
        { number: 204, name: "香菱" },
        { number: 205, name: "行秋" },
        { number: 206, name: "重雲" },
        { number: 207, name: "七七" },
        { number: 208, name: "刻晴" },
        { number: 209, name: "タルタリヤ" },
        { number: 210, name: "鍾離" },
        { number: 211, name: "辛炎" },
        { number: 212, name: "甘雨" },
        { number: 213, name: "胡桃" },
        { number: 214, name: "煙緋" },
    ];

}