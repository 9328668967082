import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";

interface Props {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
            textAlign: 'center',
            background: theme.palette.primary.main,
            color: '#fff',
        },
        stick: {
            color: '#fff',
        }
    }),
);

const Bottom = (props: Props) => {

    const classes = useStyles();
    return (
        <div className={classes.title}>
            <Typography variant='caption' component='h2'>
                <a href='https://teyvatsokuho.com' className={classes.stick}>
                    ©2021 原神攻略まとめ テイワット速報
                </a>
            </Typography>
        </div>
    );
}

export default Bottom;