import React, { Fragment } from "react";
import { storage } from "../firebase";
import html2canvas from "html2canvas";
import { Box, Button, createStyles, makeStyles, Paper, styled, Theme, Tooltip, Typography } from "@material-ui/core";
import AssignmentIcon from '@material-ui/icons/Assignment';
import CopyToClipboard from "react-copy-to-clipboard";
import { TuboRecord } from "../record";
import { Link } from "react-router-dom";

interface Props {
    auth_uid: string,
    record: TuboRecord,
    detail_page: boolean,
    regist: boolean,
}

// type TuboBgProps = {
//     oshi: string;
// }

const TuboPaper = styled(Paper)({
    position: 'relative',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 20,
    maxWidth: 350,
    minHeight: 180,
    textAlign: 'left',
});

// const TuboBg = styled(({ oshi, ...other }: TuboBgProps) => <Box {...other} />)({
//     '&::before': {
//         content: '""',
//         backgroundImage: ({ oshi }: TuboBgProps) => oshi,
//         backgroundPosition: 'right',
//         backgroundSize: 'cover',
//         backgroundRepeat: 'no-repeat',
//         opacity: .3,
//         top: 0,
//         left: 0,
//         bottom: 0,
//         right: 0,
//         position: 'absolute',
//         minHeight: 150,
//     },
// });

const TuboBox = styled(Box)({
    padding: 10,
});

const NameBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
});

const UIDBox = styled(Button)({
    margin: '5px',
    padding: '0px 10px',
    height: '30px',
    borderRadius: '15px',
    border: 'solid 1px',
    borderColor: '#777',
});

const StateBox = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
});

const Tag = styled(Box)({
    margin: '5px',
    padding: '0px 10px',
    height: '24px',
    borderRadius: '12px',
    background: '#039BE5',
    fontSize: '14px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
});

const CommentBox = styled(Box)({
    margin: '5px',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '12px',
    background: '#ccc',
    'white-space': 'pre-wrap',
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            maxHeight: '100%',
            maxWidth: '100%',
            bottom: 0,
            right: 0,
            position: 'absolute',
            opacity: .3,
            objectFit: 'cover',
        },
        uid: {
            position: 'absolute',
            top: 35,
            left: 10,
        },
        dummy_button: {
            width: '100%',
            content: '""',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            position: 'absolute',
        },
    }),
);

const Record = (props: Props) => {

    const classes = useStyles();
    const [openTip, setOpenTip] = React.useState<boolean>(false);
    const handleCopyButton = () => { setOpenTip(true) };
    const handleCloseTip = () => { setOpenTip(false) };
    const record = props.record;

    React.useEffect(() => {
        if (props.regist) {
            const target = document.getElementById("ogp");
            if (target !== null) {
                html2canvas(target, { scale: 2 }).then(canvas => {
                    canvas.toBlob((blob) => {
                        //upload to storage
                        if (blob !== null) {
                            const storageRef = storage.ref().child('cards/' + record.uid + '.png');
                            storageRef.put(blob);
                        }
                    });
                });
            }
        }
        // eslint-disable-next-line
    }, []);

    const handleClickCard = () => {
        if (!openTip) {
            console.log('click card')
        }
    }

    //const image = 'url(' + process.env.PUBLIC_URL + '/image/' + ('0000' + record.oshi).slice(-4) + '.png)';
    const image = process.env.PUBLIC_URL + '/image/' + ('0000' + record.oshi).slice(-4) + '.png';
    return (
        <Fragment>
            <TuboPaper elevation={3} id='ogp' onClick={handleClickCard}>
                <img src={image} className={classes.img} alt='推しキャラ' />
                <TuboBox>
                    <NameBox>
                        <div>{record.screen_name.trim() !== "" ? record.screen_name : "名無しの旅人"}さん</div>
                        {(!props.detail_page && !props.regist) && <div><Typography variant='caption'>{record.created_at?.toLocaleString()}</Typography></div>}
                    </NameBox>
                    <br /><br /><br />
                    {record.comment.trim() !== "" && <CommentBox>{record.comment}</CommentBox>}
                    <StateBox>
                        <Tag>{record.platform_str}</Tag>
                        {record.checks.get.map((state) => { return state.state && <Tag>{state.title}</Tag> })}
                    </StateBox>
                </TuboBox>
                <Button
                    className={classes.dummy_button}
                    component={Link}
                    to={`/_uid/${record.uid}`} />
                <Tooltip
                    arrow
                    open={openTip}
                    onClose={handleCloseTip}
                    placement='top'
                    title="UIDをコピーしました"
                    className={classes.uid}>
                    <CopyToClipboard text={record.uid}>
                        <UIDBox
                            endIcon={<AssignmentIcon />}
                            onClick={handleCopyButton}>
                            UID {record.uid}
                        </UIDBox>
                    </CopyToClipboard>
                </Tooltip>
            </TuboPaper>
        </Fragment >
    );
}

export default Record;