import { store } from "./firebase";
import "firebase/firestore";
import { QueryDocumentSnapshot } from "@firebase/firestore-types";
import RecordState from "./state";

export class TuboRecord {

    constructor(
        public auth_uid: string,
        public uid: string,
        public screen_name: string,
        public oshi: number,
        public platform: string,
        public checks: RecordState,
        public comment: string,
        public created_at: Date,
    ) { }

    get platform_str(): string {
        switch (this.platform) {
            case 'mobile':
                return 'モバイル/PC';
            case 'ps':
                return 'PS4/PS5';
            default:
                return "";
        }
    }

    public async add() {
        await store.collection('records').doc(this.uid).withConverter(recordConverter).set(this);
    }
}

export const recordConverter = {
    toFirestore: function (record: TuboRecord) {
        let i = 0;
        const data = {
            auth_uid: record.auth_uid,
            screen_name: record.screen_name,
            oshi: record.oshi,
            platform: record.platform,
            [`${record.checks.get[i].id}`]: record.checks.get[i++].state,
            [`${record.checks.get[i].id}`]: record.checks.get[i++].state,
            [`${record.checks.get[i].id}`]: record.checks.get[i++].state,
            [`${record.checks.get[i].id}`]: record.checks.get[i++].state,
            [`${record.checks.get[i].id}`]: record.checks.get[i++].state,
            comment: record.comment,
            created_at: record.created_at,
        }
        return data;
    },
    fromFirestore: function (doc: QueryDocumentSnapshot) {
        const data = doc.data();
        let state = new RecordState(false);
        state.get.forEach((value) => { state.changeState(value.id, data[value.id]) });
        return new TuboRecord(
            data.auth_uid,
            doc.id,
            data.screen_name,
            data.oshi,
            data.platform,
            state,
            data.comment,
            data.created_at.toDate()
        );
    }
}