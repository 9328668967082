const checks = [
    { id: 'mugon_ok', title: '無言OK' },
    { id: 'direct_in', title: '直接加入できます' },
    { id: 'buy_free', title: '買い物ご自由に' },
    { id: 'help', title: '助力します' },
    { id: 'housing', title: 'レイアウトに力入れてます' },
];

class State {
    constructor(
        public id: string,
        public state: boolean,
        public title: string,
    ) { }
}

export default class RecordState {
    private state = new Array<State>();
    constructor(check: boolean) {
        checks.forEach((value) => { this.state.push(new State(value.id, check, value.title)) });
    }

    get get(): Array<State> { return this.state }

    public changeState(id: string, value: boolean) {
        const index = this.state.findIndex(state => state.id === id);
        this.state[index].state = value;
        return this;
    }

    public getState(index: number) {
        return this.state[index].state;
    }
}