import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { store } from "../firebase";
import { TuboRecord } from "../record";
import Record from "./Record";
import CreateIcon from '@material-ui/icons/Create';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Box, createStyles, Fab, makeStyles, Theme } from "@material-ui/core";
import Title from "./Title";
import { Button } from "@material-ui/core";
import Bottom from "./Bottom";
import Filter from "./Filter";
import { recordConverter } from "../record";
import ScrollToTop from "./ScrollTop";
import RecordState from "../state";

interface Props {
    uid: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        navi: {
            textAlign: 'center',
        },
        button: {
            margin: theme.spacing(1),
            textTransform: 'none',
        },
    }),
);

const List = (props: Props) => {

    const classes = useStyles();
    const [records, setRecords] = React.useState([] as TuboRecord[]);
    const perPage = 10;
    const [page, setPage] = React.useState(0);
    const [nextFlg, setNextFlg] = React.useState(true);
    const [region, setRegion] = React.useState({
        start: new Date(),
        end: new Date(),
    });
    const [show, setShow] = React.useState({
        prev: false,
        next: false,
    });
    const [state, setState] = React.useState({
        state: new RecordState(true),
        mobile: true,
        ps: true,
    });

    const setStateInit = () => {
        setPage(0);
        setShow({ prev: false, next: false });
        setNextFlg(true);
    }
    //前のページボタン
    const handleClickBefore = () => {
        setShow({ prev: page > 1, next: true, });
        setNextFlg(false);
        setPage(page - 1);
    }
    //次のページボタン
    const handleClickNext = () => {
        setShow({ ...show, prev: true, });
        setNextFlg(true);
        setPage(page + 1);
    }

    async function getList(next_flg: boolean) {
        let query = store.collection('records').orderBy('created_at', 'desc');

        //フィルタ条件の作成
        if (!state.mobile && state.ps) {
            //PS
            query = query.where('platform', '==', 'ps');
        } else if (state.mobile && !state.ps) {
            //mobile
            query = query.where('platform', '==', 'mobile');
        }

        let snapshot;
        if (page === 0) {
            //初期ページ
            snapshot = await query.limit(perPage).withConverter(recordConverter).get();
        } else {
            //2ページ目以降
            if (next_flg) {
                //次のページは前のページの最後より後
                snapshot = await query.startAfter(region.end).limit(perPage).withConverter(recordConverter).get();
            } else {
                //前のページは次のページの最初より前
                snapshot = await query.endBefore(region.start).limitToLast(perPage).withConverter(recordConverter).get();
            }
        }

        if (next_flg && snapshot.docs.length > 0) {
            //さらに次のページがあるかどうか
            const nextsnap = await query
                .startAfter(snapshot.docs[snapshot.docs.length - 1]
                    .data().created_at).limit(perPage).get();
            setShow({ ...show, next: nextsnap.docs.length > 0, });
        }

        if (snapshot.docs.length > 0) {
            //表示中の最初と最後の更新日付
            setRegion({
                start: snapshot.docs[0].data().created_at,
                end: snapshot.docs[snapshot.docs.length - 1].data().created_at,
            });
        }

        return snapshot.docs;
    }

    React.useEffect(() => {
        getList(nextFlg).then(list => {
            let tubo_list = new Array<TuboRecord>();
            list.forEach(doc => {
                let data = doc.data();
                tubo_list.push(data);
            });
            setRecords([...tubo_list]);
        });
        // eslint-disable-next-line
    }, [page, state]);

    return (
        <Fragment>
            <ScrollToTop page={page} />
            <Title title="壺フレ募集掲示板" />
            <br />
            <Filter state={state} setState={setState} setStateInit={setStateInit} />
            <br />
            {records.map(record => {
                return <Record key={record.uid} auth_uid={props.uid} record={record} detail_page={false} regist={false} />
            })}
            <Box className={classes.navi}>
                {records.length === 0 && '壺フレを募集してみましょう！'}
                {show.prev &&
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleClickBefore}
                        startIcon={<NavigateBeforeIcon />}>
                        prev
                    </Button>}
                {show.next &&
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleClickNext}
                        endIcon={<NavigateNextIcon />}>
                        next
                    </Button>}
            </Box>
            <Fab
                className={classes.fab}
                variant="extended"
                color="primary"
                aria-label="add"
                component={Link}
                to="/form">
                <CreateIcon />募集する
            </Fab>
            <Bottom />
        </Fragment>
    );
}

export default List;