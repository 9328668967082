import React from "react";
import { useParams } from "react-router-dom";
import { Box, styled } from "@material-ui/core";
import { store } from "../firebase";
import { TuboRecord } from "../record";
import Record from "./Record";
import TweetButton from "./TweetButton";
import BackButton from "./BackButton";
import { recordConverter } from "../record";

interface Props {
    auth_uid: string,
}

interface RouteProps {
    uid: string,
}

const DetailBox = styled(Box)({
    padding: '20px',
    textAlign: 'center',
});

const Detail = (props: Props) => {

    async function getRecord(uid: string) {
        const record = await store.collection('records').doc(uid).withConverter(recordConverter).get();
        if (record.exists) {
            return record.data();
        }
        return undefined;
    }

    const { uid } = useParams<RouteProps>();
    const [record, setRecord] = React.useState<TuboRecord>();

    React.useEffect(() => {

        if (uid !== undefined) {
            getRecord(uid).then(value => {
                setRecord(value);
            });
        }
    }, [uid]);

    if (record !== undefined) {
        return (
            <DetailBox>
                <div style={{ fontWeight: 900 }}>壺フレ募集中</div>
                <br />
                <Record auth_uid={props.auth_uid} record={record} detail_page={true} regist={false} />
                <BackButton title='募集一覧へ' />
                {record.auth_uid === props.auth_uid && <TweetButton uid={uid} />}
            </DetailBox>
        );
    } else {
        return (<div>このページはありません</div>);
    }
}

export default Detail;