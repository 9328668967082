import { useEffect } from "react";

interface Props {
    page: number,
}

export default function ScrollToTop(props: Props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.page]);

    return null;
}