import React from "react";
import { Box, Checkbox, createStyles, FormControlLabel, makeStyles, Theme, Typography } from "@material-ui/core";
import RecordState from "../state";

interface Props {
    state: {
        state: RecordState,
        mobile: boolean,
        ps: boolean,
    },
    setState: React.Dispatch<React.SetStateAction<{
        state: RecordState,
        mobile: boolean,
        ps: boolean,
    }>>,
    setStateInit: () => void,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            marginRight: 'auto',
            marginLeft: 'auto',
            maxWidth: 400,
            textAlign: 'center',
        },
    }),
);

const Filter = (props: Props) => {
    const classes = useStyles();
    //let checks = props.state.state;
    let mobile = props.state.mobile;
    let ps = props.state.ps;
    const setState = props.setState;
    const setStateInit = props.setStateInit;
    /* const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checks.changeState(event.target.id, event.target.checked);
        setState({ ...props.state, 'state': checks });
    }; */
    const handlePlatformChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            //チェックを外した場合もう片方はチェックされている状態にする
            if (event.target.id === 'mobile') {
                setState({ ...props.state, 'mobile': false, 'ps': true });
            } else {
                setState({ ...props.state, 'mobile': true, 'ps': false });
            }
        } else {
            setState({ ...props.state, [event.target.id]: event.target.checked });
        }
        //初期ページに戻す
        setStateInit();
    };
    return (
        <Box className={classes.box}>
            <FormControlLabel
                control={<Checkbox
                    checked={mobile}
                    size='small'
                    onChange={handlePlatformChange}
                    id='mobile' />}
                label={<Typography variant='caption'>モバイル/PC</Typography>}
            />
            <FormControlLabel
                control={<Checkbox
                    checked={ps}
                    size='small'
                    onChange={handlePlatformChange}
                    id='ps' />}
                label={<Typography variant='caption'>PS4/PS5</Typography>}
            /><br />
            {/* {checks.get.map((check) => {
                return (
                    <FormControlLabel
                        control={<Checkbox
                            checked={check.state}
                            size='small'
                            onChange={handleCheckChange}
                            id={check.id} />}
                        label={<Typography variant='caption'>{check.title}</Typography>}
                    />
                );
            })} */}
        </Box>
    );
}

export default Filter;