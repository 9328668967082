import React from 'react';
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

interface Props {
    title: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            margin: theme.spacing(1),
            textTransform: 'none',
        },
    }),
);

export default function BackButton(props: Props) {
    const classes = useStyles();
    return (
        <Fab
            className={classes.fab}
            variant="extended"
            color="primary"
            aria-label="back"
            component={Link}
            to="/">
            <ArrowBackIcon />{props.title}
        </Fab>
    );
}