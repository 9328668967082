import React from 'react';
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import { TwitterShareButton } from 'react-share';

interface Props {
    uid: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            margin: theme.spacing(1),
            textTransform: 'none',
        },
    }),
);

export default function TweetButton(props: Props) {
    const classes = useStyles();
    const title = `壺フレを募集しています！
UID:${props.uid}
`;
    return (
        <TwitterShareButton
            url={'https://tubo.teyvatsokuho.com/uid/' + props.uid}
            title={title}
            hashtags={['原神', '原神フレンド募集']} >
            <Fab
                className={classes.fab}
                variant="extended"
                color="primary"
                aria-label="regist">
                <TwitterIcon />Tweet
                        </Fab>
        </TwitterShareButton >

    );
}