import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBLz3iU0vyRd75LOj1RniCHa6O6ikEFa24",
    authDomain: "tubo-9d02e.firebaseapp.com",
    projectId: "tubo-9d02e",
    storageBucket: "tubo-9d02e.appspot.com",
    messagingSenderId: "636605187426",
    appId: "1:636605187426:web:e9725c757038951c85983b",
    measurementId: "G-XGGH48BMZS"
};

firebase.initializeApp(firebaseConfig);

var auth_obj = firebase.auth();
var store_obj = firebase.firestore();
var storage_obj = firebase.storage();
export default firebase;
export const auth = auth_obj;
export const store = store_obj;
export const storage = storage_obj;