import React, { Fragment } from "react";
import { Checkbox, createStyles, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, makeStyles, MenuItem, Radio, RadioGroup, Select, TextField, Theme } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import { TuboRecord } from "../record";
import Character from "../character";
import Title from "./Title";
import Record from "./Record";
import TweetButton from "./TweetButton";
import BackButton from "./BackButton";
import Bottom from "./Bottom";
import RecordState from "../state";

interface Props {
    auth_uid: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        div_form: {
            margin: theme.spacing(2),
        },
        div_fab: {
            width: '100%',
            textAlign: 'center',
        },
        fab: {
            margin: theme.spacing(1),
            textTransform: 'none',
        },
    }),
);

const Form = (props: Props) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        auth_uid: props.auth_uid,
        uid: "",
        screen_name: "",
        oshi: 0,
        platform: "mobile",
        checks: new RecordState(false),
        mugon_ok: false,
        direct_in: false,
        buy_free: false,
        help: false,
        housing: false,
        comment: "",
        created_at: new Date(),
        no_error: false,
        regist: false,
    });

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.id === 'uid') {
            setState({ ...state, [event.target.id]: event.target.value, 'no_error': event.target.value.length >= 9 })
        } else {
            setState({ ...state, [event.target.id]: event.target.value })
        }
    };

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.id]: event.target.checked });
        setState({ ...state, 'checks': state.checks.changeState(event.target.id, event.target.checked) });
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, 'platform': event.target.value });
    };

    const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof state;
        setState({
            ...state,
            [name]: event.target.value,
        });
        const img = new Image();
        img.src = process.env.PUBLIC_URL + '/image/' + ('0000' + event.target.value).slice(-4) + '.png';
    };

    const addRecord = () => {
        setState({ ...state, 'created_at': new Date() });
        let record = new TuboRecord(
            state.auth_uid,
            state.uid,
            state.screen_name,
            state.oshi,
            state.platform,
            state.checks,
            state.comment,
            state.created_at,
        );
        record.add();
        setState({ ...state, 'regist': true });
    }

    const jsx_form = (
        <form>
            {/* -----------プラットフォーム----------- */}
            <FormControl component="fieldset">
                <FormLabel component="legend">プラットフォームを選択してください</FormLabel>
                <RadioGroup row aria-label="platform" id="platform" value={state.platform} onChange={handleRadioChange}>
                    <FormControlLabel value="mobile" control={<Radio />} label="モバイル/PC" />
                    <FormControlLabel value="ps" control={<Radio />} label="PS4/PS5" />
                </RadioGroup>
            </FormControl><br />
            <TextField
                required
                id="uid"
                label="UID(必須)"
                style={{ width: '50%', maxWidth: '300px', }}
                margin="dense"
                variant="outlined"
                type="tel"
                inputProps={{
                    maxLength: 9
                }}
                defaultValue={state.uid}
                onChange={handleTextChange} />
            <TextField
                id="screen_name"
                label="表示名(任意)"
                style={{ width: '50%', maxWidth: '300px', }}
                margin="dense"
                variant="outlined"
                defaultValue={state.screen_name}
                onChange={handleTextChange} /><br />
            <br />
            {/* -----------各種チェックボックス----------- */}
            <FormControl component='fieldset'>
                <FormLabel component="legend">表示したい項目を選択してください</FormLabel>
                <FormGroup>
                    {state.checks.get.map((state) => {
                        return (
                            <FormControlLabel
                                control={<Checkbox checked={state.state}
                                    onChange={handleCheckChange}
                                    id={state.id} />}
                                label={state.title}
                            />
                        );
                    })}
                </FormGroup>
            </FormControl><br />
            <br />
            {/* -----------推しキャラ----------- */}
            <FormControl variant="outlined" >
                <InputLabel>推しキャラ</InputLabel>
                <Select
                    margin="dense"
                    value={state.oshi}
                    label="推しキャラ"
                    onChange={handleSelectChange}
                    inputProps={{
                        name: 'oshi',
                    }}
                >
                    <MenuItem value="0" disabled>
                        推しキャラを選択できます
                                </MenuItem>
                    {Character.list.map(value => { return <MenuItem value={value.number}>{value.name}</MenuItem> })}
                </Select>
            </FormControl><br />
            <br />
            {/* -----------コメント----------- */}
            <TextField
                id="comment"
                label="コメント(任意)"
                margin="dense"
                style={{ width: '100%', maxWidth: '600px', }}
                multiline
                InputLabelProps={{
                    shrink: true,
                }}
                rows={2}
                defaultValue={state.comment}
                variant="outlined"
                onChange={handleTextChange}
            />
            <div className={classes.div_fab}>
                <BackButton title='戻る' />
                <Fab
                    className={classes.fab}
                    disabled={!state.no_error}
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    onClick={addRecord}>
                    <SaveIcon />書き込む
                        </Fab>
            </div>
        </form>
    );

    const jsx_regist = (
        <div>

            <div className={classes.div_fab}>
                登録しました
            </div>
            <br />
            <Record auth_uid={props.auth_uid}
                record={new TuboRecord(
                    state.auth_uid,
                    state.uid,
                    state.screen_name,
                    state.oshi,
                    state.platform,
                    state.checks,
                    state.comment,
                    state.created_at,
                )}
                detail_page={false}
                regist={true} />
            <div className={classes.div_fab}>
                <BackButton title='募集一覧へ' />
                <TweetButton uid={state.uid} />
            </div>
        </div>
    );

    return (
        <Fragment>
            <Title title="壺フレ募集フォーム" />
            <div className={classes.div_form}>
                {state.regist ? jsx_regist : jsx_form}
            </div>
            <Bottom />
        </Fragment >
    );
}

export default Form;