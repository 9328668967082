import { makeStyles, Theme, createStyles, Typography, AppBar } from "@material-ui/core";

interface Props {
    title: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            padding: theme.spacing(1),
        },
        stick: {
            fontFamily: 'Stick',
            color: '#fff',
        }
    }),
);

const Title = (props: Props) => {

    const classes = useStyles();
    return (
        <AppBar position='static' className={classes.title}>
            <a href='https://teyvatsokuho.com'><Typography className={classes.stick} variant='caption' component='h2'>原神攻略まとめ テイワット速報</Typography></a>
            <Typography className={classes.stick} variant='h6' component='h1'>{props.title}</Typography>
        </AppBar>
    );
}

export default Title;