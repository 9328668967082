import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { auth } from './firebase';
import './App.css';
import Form from './components/Form';
import List from './components/List';
import Detail from './components/Detail';

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#039BE5',
    },
    secondary: {
      main: '#005a87',
    },
  },
});

function App() {

  const [uid, setUid] = React.useState('');
  React.useEffect(() => {
    auth.signInAnonymously()
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(`${errorCode}:${errorMessage}`);
      });

    auth.onAuthStateChanged((user) => {
      if (user) {
        setUid(user.uid);
      } else {
        // User is signed out
      }
    });
  }, []);

  const ListComponent = (props: any) => <List auth_uid={uid} {...props} />
  const FormComponent = (props: any) => <Form auth_uid={uid} {...props} />
  const DetailComponent = (props: any) => <Detail auth_uid={uid} {...props} />

  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <Router>
          <Switch>
            <Route exact path='/' component={ListComponent} />
            <Route path="/form" component={FormComponent} />
            <Route path="/uid/:uid" component={DetailComponent} />
            <Route path="/_uid/:uid" component={DetailComponent} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
